$height-bl-rdio: 110px;
$side-checkbox: 22px;
$wr-test-w: 1070px;
$side-brick: 32px;
$weight-border-brick: 2px;
$test-w: 450px;

$progressline-h: 34px;

$jackdaw-radio-r: 34px;
$jackdaw-radio-r-s: 24px;

.wr-jackdaw-radio {
	position: relative;
	padding-left: $jackdaw-radio-r+20px;
	height: $jackdaw-radio-r;
	line-height: $jackdaw-radio-r;
	.wr-ir {
		cursor: pointer; }
	.jackdaw-radio {
		height: $jackdaw-radio-r;
		width: $jackdaw-radio-r;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		border-radius: 50%;
		border: $border-line;
		@extend .before;
		&:before {
			transform: scale(0);
			margin: auto;
			border-radius: 50%;
			width: 60%;
			height: 60%;
			transition: .3s; } }
	.ir-d:checked+.wr-ir .jackdaw-radio:before {
		transform: scale(1);
		background-color: $c-primary; } }
.replace {
	display: inline-block;
	width: 170px;
	text-align: left; }
.wr-projects {
	display: none;
	text-align: center;
	background-color: $c-bg-gray;
	.title {
		padding: 50px 0px;
		line-height: 58px;
		font-size: 48px; }
	.projects {
		display: inline-block;
		max-width: 320px;
		width: 100%;
		background-color: $c-white;
		margin: 0 5px;
		.bl-img {
			background-size: cover;
			width: 100%;
			max-width: 100%;
			min-height: 220px; }
		.name {
			padding: 29px 0;
			font-weight: 700;
			line-height: 24px;
			font-size: 20px;
			text-align: center; } }
	.btn {
		display: block;
		max-width: 320px;
		margin: 40px auto 30px;
		.ic {
			margin-left: 10px;
			display: inline-block;
			vertical-align: middle;
			&:before,
			&:after {
				background-color: $c-white;
				width: 12px;
				top: 46%; }
			&:before {
				right: -5px; }
			&:after {
				left: -5px; } } } }
.section.test {
	padding-top: 40px;
	.title {
		padding-top: 0;
		line-height: 58px;
		font-size: 48px; }
	.wr-test {
		padding: 0 40px 0;
		max-width: $wr-test-w;
		background-color: $c-white;
		margin: auto;
		.bl-info {
			box-shadow: 0px 0px 16px rgba(127,127,127,0.1), 0px 0px 16px rgba(127,127,127,0.1), 0px 8px 14px rgba(127,127,127,0.2);
			position: relative;
			max-width: 340px;
			padding: 50px 37px 68px 50px;
			float: left;
			.title-info {
				line-height: 26px;
				font-size: 20px; }
			.ul-d {
				.li-d {
					padding-left: 66px;
					height: 45px;
					margin: 30px 0;
					&:last-of-type {
						margin-bottom: 0; } } } }
		.test {
			float: right;
			width: 100%;
			max-width: 480px;
			position: relative;
			.progressline {
				position: relative;
				max-width: 450px;
				margin: 11px auto 23px;
				height: $progressline-h;
				background-color: $c-primary-light;
				border-radius: 30px;
				overflow: hidden;
				&.step-1 .progres-bg {
					width: 25%; }
				&.step-2 .progres-bg {
					width: 50%; }
				&.step-3 .progres-bg {
					width: 75%; }
				&.step-4 .progres-bg {
					width: 86%; }
				.progres-bg {
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					height: 100%;
					background: $c-primary;
					transition: .4s ease-out width;
					z-index: 1; }
				.step-counter {
					position: relative;
					z-index: 2;
					text-align: center;
					color: $c-white;
					line-height: $progressline-h;
					font-size: 16px; } }
			.block {
				display: none;
				overflow: hidden;
				.wr-it {
					max-width: 355px; }
				&.active {
					display: block; } }
			.btn {
				max-width: 320px;
				display: block;
				margin: auto;
				button.btn {
					max-width: 355px;
					padding: 20px 0;
					line-height: 19px;
					min-height: 78px; }
				.ul-d {
					margin-bottom: 50px;
					.li-d {
						margin-bottom: 20px; } } }
			.answer {
				max-width: 320px;
				margin-bottom: 40px; }
			.query {
				color: $c-text-gray;
				margin-bottom: 30px;
				line-height: 24px;
				color: $c-gray;
				font-size: 20px;
				text-align: center;
				font-weight: 700;
				transition: .3s;
				&.error {
					color: $c-error; } } }
		#step-4 {
			.form {
				max-width: 100%;
				.title-end {
					margin-top: 40px;
					line-height: 43px;
					color: $c-primary;
					font-size: 36px;
					font-weight: 700; }
				.text {
					margin-top: 15px; }
				.wr-wr {
					margin: 40px auto 0;
					max-width: 350px;
					.btn {
						max-width: 100%; } }
				.arrow {
					background: url("#{$path-design}arrow.png");
					position: absolute;
					left: -31px;
					bottom: 64px;
					height: 93px;
					width: 73px;
					transform: rotate(-15deg); } } } }
	.bl-share {
		.text {
			text-align: center; } } }
.home-test2,
.home-msktest2 {
	.section {
		padding-top: 30px; }
	.first-form {
		background: url('#{$path-design}/bg-firstscreen.jpg') right center no-repeat;
		background-size: 50% auto; }
	.wr-bl-form-notice {
		padding-right: 450px;
		.wr-title {
			padding-right: 30px;
			.title {
				line-height: 48px;
				font-size: 38px;
				font-weight: 400;
				text-align: left;
				padding-top: 30px; }
			.text {
				line-height: 1.5;
				margin-bottom: 30px;
				font-size: 18px; } } }
	.bl-form {
		max-width: 450px;
		box-sizing: border-box;
		background-color: $c-white;
		border: $border-line;
		padding: 30px 40px;
		.title {
			padding: 30px 0 20px;
			text-align: center;
			line-height: 24px;
			margin: 0 -50px;
			font-size: 18px; }
		.text {
			font-size: 14px; } }
	.form-content {
		.text {
			text-align: center;
			margin: 0 -50px 30px; } }
	.section.thanks-catalog {}
	.first-form {
		.bl-form {
			.form {
				margin: auto; } } }
	#footer.main-footer.bgg {
		@extend .bgg; } }
@keyframes slideme {
	0% {
		left: -20%; }
	30%,100% {
		left: 120%; } }
.btn.blick {
	position: relative;
	&:before {
		content: " ";
		position: absolute;
		top: -100%;
		left: -20%;
		width: 30px;
		height: 300%;
		background: $c-white;
		animation: slideme ease-in-out 3s infinite;
		animation-delay: .05s;
		transform:  translateX(-15px) rotate(45deg);
		transition: .1s; } }

@media #{$medium-and-down} {}
@media #{$small-and-down} {
	.wr-jackdaw-radio {
		padding-left: $jackdaw-radio-r-s + 20px;
		height: $jackdaw-radio-r-s;
		line-height: $jackdaw-radio-r-s !important;
		.wr-ir {}
		.jackdaw-radio {
			height: $jackdaw-radio-r-s;
			width: $jackdaw-radio-r-s;
			&:before {} }
		.ir-d:checked+.wr-ir .jackdaw-radio:before {} }
	.wr-projects {
		.title {
			padding: 10px 0px; }
		.projects {
			display: block;
			max-width: 100%;
			margin: 0 0 10px 0;
			.bl-img {}
			.name {
				padding: 10px 0; } }
		.btn {
			max-width: 100%;
			margin: 10px 0;
			.ic {
				&:before,
				&:after {}
				&:before {}
				&:after {} } } }
	.section.test {
		padding-top: 0;
		.title {
			padding: 0; }
		.wr-test {
			padding: 0;
			max-width: 100%;
			.bl-info {
				max-width: 100%;
				padding: 10px 20px;
				float: none;
				.title-info {
					font-size: 16px;
					line-height: 1.3;
					text-align: center;
					margin-bottom: 5px; }
				.ul-d {
					.li-d {
						height: 36px;
						margin: 5px 0; } } }
			.test {
				float: none;
				max-width: 100%;
				.progressline {
					&.step-1 .progres-bg {}
					&.step-2 .progres-bg {}
					&.step-3 .progres-bg {}
					&.step-4 .progres-bg {}
					.progres-bg {}
					.step-counter {
						font-size: 14px; } }
				.block {
					.wr-it {}
					&.active {} }
				.btn {
					button.btn {}
					.ul-d {
						.li-d {} } }
				.answer {
					margin-bottom: 5px;
					&:nth-child(2n) {
						.col {
							.wr-jackdaw-radio:last-of-type {
								margin-bottom: 0; } } } }
				.query {
					font-size: 16px;
					line-height: 1.3;
					margin-bottom: 15px;
					&.error {} } }
			#step-4 {
				.form {
					.title-end {
						margin-top: 10px;
						line-height: 1.3;
						font-size: 16px;
						font-weight: 700; }
					.text {
						margin-top: 15px; }
					.wr-wr {
						margin: 15px auto 0;
						max-width: 100%;
						.name {
							margin-bottom: 5px; }
						.btn {} }
					.arrow {
						display: none; } } } }
		.bl-share {
			.text {
				.br {
					display: inline-block;
					max-width: 5px; } } } }
	.home-test2,
	.home-msktest2 {
		.section {
			box-sizing: border-box;
			padding-top: 0;
			.br {
				display: none; } }
		.wr-bl-form-notice {
			padding: 0;
			position: static;
			.title {}
			.text {
				margin-bottom: 30px; } }
		.bl-form {
			.title {
				padding: 15px 0 10px;
				margin: 0; } }
		.form-content {
			.text {
				font-size: 19px;
				line-height: 1.5;
				text-align: center;
				margin: 0 0 20px 0; } }
		.section.thanks-catalog {}
		.first-form {
			.bl-form {
				.form {
					margin: auto; } } }
		#footer.main-footer.bgg {} } }

.ssk.ssk-icon {
	color: $c-gray-light;
	padding-right: 5px;
	&:before {
		font-size: 17px; }
	&:hover {
		color: $c-primary; } }
